<template>    
  <section>
    <custom-breadcrumb
      :pageTitle="$t('Learning Paths')"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>
    
<!-- <b-card
  :title=" $t('resources.tutorials_desc')"
>
  <b-card-text>
    <ul>
      <li>{{$t('learning_path.create_desc1')}}</li>
      <li>{{$t('learning_path.edit_desc')}}</li>
      <li>{{$t('learning_path.assign_desc1')}}</li>
    </ul>
  </b-card-text>
  <b-row>
    <b-col sm="12" md="6">
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="secondary"
          block
          class="mr-1 mb-25"
          :to="{name:'learning-paths-create'}"
      >{{$t('learning_path.create_heading')}}
      </b-button>
    </b-col>
    <b-col sm="12" md="6">
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="success"
          block
          class="mr-1 mb-25"
          :to="{name:'learning-paths-assign'}"
      >{{$t('learning_path.assign_heading')}}
      </b-button>
    </b-col>
  </b-row>
</b-card> -->
    <b-row class="match-height">
      <b-col cols="12" sm="6">
        <b-card no-body>
          <b-card-body>
            <b-media no-body>
              <b-media-aside style="max-width: 100px;" class="d-none d-md-block">
                <b-link :to="{ name: 'learning-paths-create'}">
                  <b-img
                    :alt="$t('learning_path.create_heading')"
                    fluid
                    class="card-img-top"
                    :src="require('@/assets/images/illustration/list_create.png')"
                  />
                </b-link>
              </b-media-aside>

              <b-media-body class="ml-1">
                <h3 class="mt-0">
                  <b-link
                    class="text-body"
                    :to="{ name: 'learning-paths-create' }"
                  >
                    {{ $t('learning_path.create_heading') }}
                  </b-link>
                </h3>
                <b-card-text>
                  {{ $t('learning_path.create_desc1') }}
                </b-card-text>
                <b-button
                  variant="primary"
                  class="btn-wishlist"
                  :to="{ name: 'learning-paths-create' }"
                >
                  <span>{{$t('learning_path.create_heading') }}</span>
                </b-button>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6">
        <b-card no-body>
          <b-card-body>
            <b-media no-body>
              <b-media-aside style="max-width: 100px;" class="d-none d-md-block">
                <b-link :to="{ name: 'learning-paths-assign'}">
                  <b-img
                    :alt="$t('learning_path.assign_heading')"
                    fluid
                    class="card-img-top"
                    :src="require('@/assets/images/illustration/list_assign.png')"
                  />
                </b-link>
              </b-media-aside>

              <b-media-body class="ml-1">
                <h3 class="mt-0">
                  <b-link
                    class="text-body"
                    :to="{ name: 'learning-paths-assign' }"
                  >
                    {{ $t('learning_path.assign_heading') }}
                  </b-link>
                </h3>
                <b-card-text>
                  {{ $t('learning_path.assign_desc1') }}
                </b-card-text>
                <b-button
                  variant="primary"
                  class="btn-wishlist"
                  :to="{ name: 'learning-paths-assign' }"
                >
                  <span>{{$t('learning_path.assign_heading') }}</span>
                </b-button>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col cols="12"  >
        <div class="text-center">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12" >
        <h3 class="mt-0">
          {{$t('Explore') }}
        </h3>
        <learning-path-list 
          @refetch-data="getData"
        />
      </b-col>
    </b-row>

  
  </section>
</template>

<script>
import LearningPathList from './LearningPathList.vue'
import { BLink,   BCard, BCardText, BTable, BMedia, BMediaAside, BMediaBody,
  BFormDatepicker, BSpinner,
  BButton, BRow, BCol, 
  BFormCheckbox, BImg,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BAvatar, BAvatarGroup, VBTooltip,
  BCardGroup, BCardTitle, BCardFooter, BCardBody,
 } from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import Ripple from 'vue-ripple-directive'

import { 
  //getAllLearningPaths,
getContents,
getHierarchy,
getLearningPath
 } from '@/api/learningpaths'

export default {
components:{
      BLink,
      BCard, BCardText, BTable, BMedia, BMediaAside, BMediaBody,
      BFormDatepicker, BSpinner,
      BButton, BRow, BCol, 
      BFormCheckbox, BImg,
      BFormGroup, BFormInput, BForm, BFormRadio,
      BAvatar, BAvatarGroup, VBTooltip,
      BCardGroup, BCardTitle, BCardFooter, BCardBody,

      CustomBreadcrumb,
      LearningPathList,
    },
     directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data(){
        return {
            breadcrumb:[],
            loading: true
        }
    },
    created(){
      this.getData()
    },
    computed:{
      lang(){
        return this.$store.getters['user/getLang']
      }
    },
    watch:{
      lang(newLang, oldLang){  /* lanzar la llamada cada vez que cambie el idioma del usuario! */
        this.getData()
      }
    },
    methods:{
      getData(){
        this.loading = true
        let data = {
          'user_id':  this.$store.getters['user/getUserData'].id,
          'lang': this.$store.getters['user/getLang'],
          'token': this.$store.getters['user/getUserData'].sso,
        }
        const promises = [
          this.getDataContents(data),
          this.getDataHierarchy(data),
          this.getDataLPs(data)
        ];
        Promise.allSettled(promises).then((results) =>
          this.loading = false
        );
      },
      getDataContents(data){
        return new Promise((resolve, reject) => {
          this.$http.get(getContents, {params: data}).then( response => {
            this.$store.commit('learningPaths/setContents', response.data)
            return resolve(true)
          }).catch(error => {
            // console.log("Err -> ", error);
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            return reject(error)
          })
        })
      },
      getDataHierarchy(data){
        return new Promise((resolve, reject) => {
          this.$http.get(getHierarchy, {params: data}).then( response => {
              if(this.$store.getters['user/getUserData'].id == 1081){
                let myHierarchy = [
                  { "source":"smileandlearn", "subcategories": response.data } 
                ]
                this.$store.commit('learningPaths/setHierarchy', myHierarchy )
              } else {
                let myHierarchy = [
                  { "source":"own" },
                  { "source":"school" },
                  { "source":"recommended" },
                  { "source":"shared" },
                  { "source":"smileandlearn", "subcategories": response.data } 
                ]
                this.$store.commit('learningPaths/setHierarchy', myHierarchy )
              }
              return resolve(true)
            }).catch(error => {
            // console.log("Err -> ", error);
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            return reject(error)
          })
        })
      },
      getDataLPs(data){
        return new Promise((resolve, reject) => {
          this.$http.get(getLearningPath, {params: data}).then( response => {
            this.$store.commit('learningPaths/setLearningPaths', response.data)
            return resolve(true)
          }).catch(error => {
            // console.log("Err -> ", error);
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            return reject(error)
          })
        })
      },
      makeToast(variant = null, tit ='',msg = '') {
        this.$bvToast.toast(msg, {
          title: tit,
          variant,
          solid: false,
        })
      },

    }
}
</script>

<style lang="scss" scoped>
.horizontal-card{
  display: grid;
  grid-template-columns: 1fr auto;
}
</style>
